import { createContext, useContext, useEffect, useState } from "react";
import { ConfirmIdentity } from "../confirm-identity/ConfirmIdentity";
import { OverlayContext } from "./OverlayContext";

export const loggedType = {
    EXPIRED: "EXPIRED",
    LOGGED: "LOGGED",
    NOT_LOGGED: "NOT_LOGGED"
};

function isTokenExpired(token) {
    const tokenData = JSON.parse(atob(token.split('.')[1]));
    const expirationTime = tokenData.exp * 1000;
    const currentTime = Date.now();
    return currentTime > expirationTime;
}

export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const[isLogged, setLogged] = useState(undefined);
    const { addOverlay, clearOverlay } = useContext(OverlayContext);
    const readyToRender = isLogged !== undefined;

    const setAsLogged = () => setLogged(loggedType.LOGGED);
    
    const checkAuthStatus = () => {
        const userToken = localStorage.getItem("userToken");
        let loggin;
        if(userToken){
            if(isTokenExpired(userToken)){
                loggin = loggedType.EXPIRED;
            }else{
                loggin = loggedType.LOGGED;
            }
        }else{
            loggin = loggedType.NOT_LOGGED;
        }
        setLogged(loggin);
    };

    useEffect(()=>{
        if( isLogged === undefined){
            checkAuthStatus();
        }else if( isLogged === loggedType.EXPIRED){
            addOverlay(
                <ConfirmIdentity 
                    onLoginSuccess={setAsLogged}
                />
            )
        }else if( isLogged === loggedType.NOT_LOGGED){
            window.location = "/auth/login-2";
        }else{
            clearOverlay();
        }
    },[isLogged]);

    return(
        <AuthContext.Provider 
            value={{
                isLogged,
                setAsLogged,
                checkAuthStatus
            }}
        >
            { readyToRender ? 
                <>
                    {children}
                </>
                : null
            }
        </AuthContext.Provider>
    )
    
}