import {
  MonitorOutlined,
  UpCircleOutlined,
  ContactsOutlined,
  ScheduleOutlined,
  LineChartOutlined,
  AuditOutlined,
  NotificationOutlined,
} from "@ant-design/icons";
import { CockpitIcon, CRMScoreIcon } from "components/hubkn/icons/customIcons";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import { ForecastMenuItem } from "views/app-views/forecast/utils/forecastIcons";

export const cockpitMenu = [
  {
    key: "home",
    path: `${APP_PREFIX_PATH}/home`,
    title: "home",
    icon: CockpitIcon,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "individual",
    path: `${APP_PREFIX_PATH}/individual`,
    title: "individual",
    icon: AuditOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "sales",
    path: `${APP_PREFIX_PATH}/closers`,
    title: "sales",
    icon: LineChartOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "forecast",
    path: `${APP_PREFIX_PATH}/basic-forecast`,
    title: "forecastMenu",
    icon: ForecastMenuItem,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "marketing",
    path: `${APP_PREFIX_PATH}/marketing`,
    title: "marketingMenu",
    icon: NotificationOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "activities",
    path: `${APP_PREFIX_PATH}/activities`,
    title: "activities",
    icon: ScheduleOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "sdr",
    path: `${APP_PREFIX_PATH}/sdr`,
    title: "sdr",
    icon: ContactsOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "conversion",
    path: `${APP_PREFIX_PATH}/conversion`,
    title: "conversion",
    icon: MonitorOutlined,
    breadcrumb: false,
    submenu: [],
  },
];

export const upgradeMenu = [
  {
    key: "upgrade",
    path: `${APP_PREFIX_PATH}/upgrade`,
    title: "upgrade",
    icon: UpCircleOutlined,
    breadcrumb: false,
    upgrade: true,
    submenu: [],
  },
];

const dashBoardNavTree = [
  {
    key: "0-home",
    title: "homepage",
    path: `${APP_PREFIX_PATH}/home`,
    breadcrumb: false,
    submenu: []
  },
  {
    key: "1-crmadmin",
    title: "dataQuality",
    breadcrumb: false,
    submenu: [
      {
        key: "1.1-crmscore",
        path: `${APP_PREFIX_PATH}/crmscore`,
        title: "crmscore",
        icon: CRMScoreIcon,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
  {
    key: "2-home",
    title: "execution",
    breadcrumb: false,
    submenu: [
      {
        key: "2.1-individual",
        path: `${APP_PREFIX_PATH}/individual`,
        title: "individual",
        icon: AuditOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "2.2-conversion",
        path: `${APP_PREFIX_PATH}/conversion`,
        title: "conversion",
        icon: MonitorOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "2.3-activities",
        path: `${APP_PREFIX_PATH}/activities`,
        title: "activities",
        icon: ScheduleOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "2.4-sdr",
        path: `${APP_PREFIX_PATH}/sdr`,
        title: "sdr",
        icon: ContactsOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ]
  },
  {
    key: "3-areas",
    title: "areas",
    breadcrumb: false,
    submenu: [
      {
        key: "3.1-marketing",
        path: `${APP_PREFIX_PATH}/marketing`,
        title: "marketingMenu",
        icon: NotificationOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "3.2-sales",
        path: `${APP_PREFIX_PATH}/closers`,
        title: "sales",
        icon: LineChartOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
  {
    key: "tv-mode",
    title: "tvmode",
    path: `${APP_PREFIX_PATH}/tv-mode`,
    breadcrumb: false,
    submenu: []
  },
  // {
  //   key: "4-gpt",
  //   title: "jordangpt",
  //   path: `${APP_PREFIX_PATH}/crmscore`,
  //   breadcrumb: false,
  //   submenu:[]
  // },
  // {
  //   key: "5-datahub",
  //   title: "dataIntelligence",
  //   path: `${APP_PREFIX_PATH}/crmscore`,
  //   breadcrumb: false,
  //   submenu:[]
  // },
  {
    key: "6-settings",
    title: "adjustments",
    path: `${APP_PREFIX_PATH}/owners-settings`,
    breadcrumb: false,
    submenu: []
  }
];


const navigationConfig = [...dashBoardNavTree];

export default navigationConfig;
