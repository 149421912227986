import { Button } from "antd";
import { languageText } from "auth/Context";
import Flex from "components/shared-components/Flex";
import {motion}  from "framer-motion"

const notAllowedUpgradeImage = "/img/others/not-allowed-upgrade.svg";
const bodyText = languageText();

export const NotAllowedAccess = () => {
    const onUpgradeClicked = () => window.location = "/app/upgrade";
    return(
        <motion.div 
            initial={{opacity:0.25}} 
            animate={{opacity:1}}
            transition={{duration:0.4}}
        >
            <Flex 
                flexDirection="column" 
                justifyContent="center" 
                alignItems="center"
                className="w-100 py-5 positionn-relative"
            >
                    <img 
                        src={notAllowedUpgradeImage}
                        alt="An abstract curve shape filled with light gray color, containing a green arrow."
                    />
                    <div 
                        className="d-flex flex-column w-100 justify-content-center align-items-center"
                        style={{transform:"translateY(-50%)"}}
                    >
                        <p>{bodyText.notAllowedToViewPage}</p>
                        <Button 
                            type="primary" 
                            onClick={onUpgradeClicked}
                        >
                            {bodyText.upgradeNow}
                        </Button>
                    </div>
            </Flex>
        </motion.div>
    )
};