import moment from "moment";

export const dateQueryFormat = (date) => {
    return moment(date).utc().format("yyyy-MM-DD HH:mm:ss")
}

export const datePresentationFormat = (date) => {
    return moment(date).utc().add(moment().utcOffset(), 'minutes').format("yyyy-MM-DD HH:mm:ss")
}

export const periodReferenceFormat = (date) => {
    return moment(date).format('DD/MM/yyyy')
}

export const dateFormatByLanguage = (date) => {
    return localStorage?.getItem('lang')?.includes('en') ? moment(date).format('MM/DD/yyyy') : moment(date).format('DD/MM/yyyy');
}