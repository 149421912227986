import fetch from "node-fetch";
import jwt from "jwt-decode";
import { GoalsGeneralInterface } from "views/app-views/owners/interfaces/GoalsInterfaces";
import { api, newApi } from "controllers/ApiClient";
import moment from "moment";
import { DATE_FORMAT_DD_MM_YYYY_HH_mm } from "constants/DateConstant";
import { dateQueryFormat } from "utils/dateFunctions";

const BASE_URL = `https://api.hubkn.com/`;

const updateAccessCode = async () => {
  // const searchParams = document.location.search.substring(1)
  //   ? new URLSearchParams(document.location.search.substring(1))
  //   : undefined;
  // let keyFetch = searchParams
  //   ? await JSON.parse(searchParams.get("token"))
  //   : { access_token: localStorage.getItem("userToken") };

  // if (keyFetch && keyFetch.access_token) {
  //   localStorage.setItem("userToken", keyFetch.access_token);
  // } else {
  //   // window.location = "/auth/login-2";
  // }

  const FETCH_URL = `${BASE_URL}auth/login`;
  let token = { email: "" };
  if (localStorage.getItem("userToken")) {
    try {
      token = jwt(localStorage.getItem("userToken"));
      console.log(token);
    } catch (error) { }
  }
  if (!token) {
    // window.location.href = "https://app.hubkn.com";
  }
  let userEmail = "";
  let userPassword = "";
  let tempParams = JSON.stringify({ email: userEmail, password: userPassword });

  if (token.email.includes("fretefy")) {
    tempParams = JSON.stringify({
      email: "fretefy@test.com",
      password: "123456",
    });
  } else {
    if (token.email.includes("gestao")) {
      tempParams = JSON.stringify({
        email: "gestao@test.com",
        password: "123456",
      });
    }
  }
  try {
    const fetchRawData = await fetch(FETCH_URL, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: tempParams,
    });
    const fetchDataJson = await fetchRawData.json();
    if (fetchDataJson.success) {
      localStorage.setItem("userToken", fetchDataJson.data.access_token);
      return fetchDataJson.data.access_token;
    } else {
      throw new Error("Falha no login");
    }
  } catch (error) {
    console.log(error);
    return { error: true };
  }
};

const getContractStatus = async () => {
  const access = localStorage.getItem("userToken");
  if (access) {
    let userData = { contract: undefined };
    if (access) userData = jwt(access);
    if (userData.contract !== undefined) {
      localStorage.setItem("trial", userData.trial);
    }
    return userData.contract;
  } else {
    window.location.href = "/auth/login-v2";
    return false;
  }
};

const validateContractStatus = async (contract) => {
  const status = await getContractStatus();
  if (status) {
    return contract === status || contract === localStorage.getItem("trial");
  } else {
    return "login";
  }
};

export const fetchGeneralMrrCardData = async (dates) => {
  const { startDate, endDate } = dates;
  try {
    const body = {
      startDate: dateQueryFormat(startDate),
      endDate: dateQueryFormat(endDate),
      period: "month",
    };
    const generalData = await api.post("/cockpit/period-goal", body, {
      Accept: "application/json",
      "Content-Type": "application/json",
    });
    console.log(generalData);
    return generalData;
  } catch (error) {
    console.log(error);
    return undefined;
  }
};

const fetchCardsData = async (owners, startDate, endDate) => {
  let ownersData = [];
  for (let employee of owners) {
    const params = {
      startDate: dateQueryFormat(startDate),
      endDate: dateQueryFormat(endDate),
      period: "month",
      ownerId: employee.id,
    };
    const fetchIndividualData = await newApi.get(
      "/cockpit/period-goal",
      { params }
    );
    if (fetchIndividualData) {
      fetchIndividualData.name = employee.name;
      ownersData.push({
        mrrData: fetchIndividualData,
        userData: employee
      });
    }
  }
  return ownersData.length > 0 ? { ownersData: ownersData } : { error: true };
};

const fetchIndividualSalesPerformance = async (ownerId, startDate, endDate) => {
  const FETCH_URL = `${BASE_URL}cockpit/period-goal`;
  const accessCode = localStorage.getItem("userToken");
  try {
    const fetchRawIndividualData = await fetch(FETCH_URL, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessCode}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        startDate: startDate,
        endDate: endDate,
        period: "month",
        ownerId: ownerId,
      }),
    });
    const fetchIndividualDataJson = await fetchRawIndividualData.json();
    return fetchIndividualDataJson.data;
  } catch (error) {
    return false;
  }
};

const fetchGeneralGoals = async () => {
  const FETCH_URL = `${BASE_URL}goals?belongs=GENERAL`;
  const accessCode = localStorage.getItem("userToken");
  try {
    const fetchRawData = await fetch(FETCH_URL, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessCode}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    const fetchDataJson = await fetchRawData.json();
    if (fetchDataJson.success) {
      console.log(fetchDataJson.data);

      return fetchDataJson.data && fetchDataJson.data.length > 1
        ? fetchDataJson.data
        : GoalsGeneralInterface;
    } else {
      throw new Error("Não foi possível buscar os dados");
    }
  } catch (error) {
    console.log(error);
    return [];
  }
};

export {
  fetchCardsData,
  updateAccessCode,
  getContractStatus,
  validateContractStatus,
  fetchGeneralGoals,
  fetchIndividualSalesPerformance,
};
