import { newApi } from "./ApiClient"

export const isClientIntegrated = async () => {
    const result = await newApi.get("/users/me");
    return !!result?.clientId;
}

export const getClient = async () => {
    return newApi.get("/contracts/me");
}

export const getClientInfo = async () => {
    return newApi.get("/clients/me");
}

export const getClientsList = async () => {
    const result = await newApi.get("/clients/associates")
    return result;
}
export function getUrlByPlatformID(platformID, domain, dealId) {
    return platformID !== null || undefined
        ? `https://${domain}.pipedrive.com/deal/${dealId}`
        : `https://app.hubspot.com/contacts/${platformID}/deal/${dealId}`;
}

export const changeActiveClient = async (clientId) => {
    const body = { clientId }
    const result = await newApi.post("/clients/connect/me", body);
    return result;
}

export const contractType = {
    SCORE: 'SCORE',
    SCOREPRO: 'SCOREPRO',
    COCKPIT: 'COCKPIT',
    SPACESHIP: 'SPACESHIP',
    NO_CONTRACT: 'NO_CONTRACT'
};

export const contractList = [
    contractType.SCORE,
    contractType.SCOREPRO,
    contractType.COCKPIT,
    contractType.SPACESHIP,
    contractType.NO_CONTRACT
];

export const getContract = async () => {
    const clients = await getClient();
    if (clients) {
        const activeClient = clients?.find(client => client?.status === "ACTIVE");
        if (activeClient?.clientId) {
            return activeClient?.type;
        } else {
            return contractType.NO_CONTRACT;
        }
    } else {
        return contractType.NO_CONTRACT
    }
};

export const getTrial = async () => {
    const trial = await newApi.get("/trials/me");
    if (trial?.length > 0 && trial[0].status === 'ACTIVE') {
        return trial[0]?.type;
    }
};

export const getCurrentContract = async () => {
    const trial = await getTrial();
    if (trial)
        return trial;
    else
        return await getContract();
};