import jwt from "jwt-decode";
import moment from "moment";
import englishText from "../lang/locales/en_US.json";
import portugueseText from "../lang/locales/pt_BR.json";

export const fetchData = async (dateTextValue) => {
  const dateMilliseconds = handleDate(dateTextValue);
  // const searchParams = document.location.search.substring(1)
  //   ? new URLSearchParams(document.location.search.substring(1))
  //   : undefined;
  // let keyFetch = searchParams
  //   ? await JSON.parse(searchParams.get("token"))
  //   : { access_token: localStorage.getItem("userToken") };

  // if (keyFetch && keyFetch.access_token) {
  //   console.log("Atualizou");
  //   localStorage.setItem("userToken", keyFetch.access_token);
  // } else {
  //   window.location = "/auth/login-2";
  // }

  let data = undefined;
  // await updateAccessCode();

  // setUserData((prevent)
  //   return { ...prevent, user };
  // });
  let user = {};
  try {
    user = jwt(localStorage.getItem("userToken"));

    if (user.exp * 1000 <= +moment().utcOffset(0).valueOf()) {
      // window.location = "/auth/login-2";
    }
    // setUserName(user.name);
    // setUserCompany(user.company);

    console.time();
    let tries = 0;
    do {
      try {
        const accessCode = localStorage.getItem("userToken");
        const startDate = moment().startOf("month").format("yyyy-MM-DD HH:mm:ss");
        const endDate = moment().endOf("month").endOf("day").format("yyyy-MM-DD HH:mm:ss");
        await fetch(
          `https://api.hubkn.com/cockpit?startDate=${startDate}&endDate=${endDate}`,
          // "https://us-central1-sales-mentor-prod.cloudfunctions.net/dev-common-score",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${accessCode}`,
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
          .then(async (response) => {
            let result = await response.json();
            data = result.data;
          })
          .catch((error) => {
            console.error(error);
          });
      } catch (error) {
        //   window.location.href = "/tokenExpired";

        console.log("Falhou na requisição", error);
      }
      tries++;
    } while (!data && tries < 6);
    console.timeEnd();
  } catch {
    window.location.href = "/auth/login-2";
  }
  return data ? data : { error: true };
};

const handleDate = (date) => {
  switch (date) {
    case "Hoje":
      return moment().utcOffset(0).startOf("day").valueOf();
    case "Ontem":
      return moment().utcOffset(0).subtract(1, "day").startOf("day").valueOf();
    case "Mês Passado":
      return moment().utcOffset(0).startOf("month").startOf("day").valueOf();
    case "Último Trimestre":
      return moment().utcOffset(0).startOf("quarter").startOf("day").valueOf();
  }
};

export const languageText = () => {
  let language = navigator.language;
  if (localStorage.getItem("lang")) {
    language =
      localStorage.getItem("lang").includes("pt") || localStorage.getItem("lang").includes("en")
        ? localStorage.getItem("lang")
        : "en_US";
  } else {
    localStorage.setItem("lang", language);
  }

  if (language.includes("pt")) {
    return portugueseText;
  }
  if (language.includes("en")) {
    return englishText;
  }
  return englishText;
};
