import axios from "axios";

export const api = axios.create({
  baseURL: "https://api.hubkn.com",
  headers: { "Content-Type": "application/json" },
});

api.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("userToken")
    ? localStorage.getItem("userToken")
    : "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI1YTNhNjU5NC1hMzgzLTRhYmItYWU0YS0zNTk2ZWYyNDg3OWQiLCJlbWFpbCI6ImFkbWluQGRldi5jb20iLCJuYW1lIjoiQWRtaW4gRGV2Iiwicm9sZSI6IkFETUlOIiwiaWF0IjoxNjkwNTY2Njk5LCJleHAiOjE2OTA2NTMwOTl9.Lx9hT4WAzhTihYIaW9G5Pvh80T41D2cv6jL2OlHCx6k";

  if (token) {
    config.headers = { Authorization: `Bearer ${token}` };
  }

  return config;
});

api.interceptors.response.use(
  function (response) {
    return response.data.data;
  },
  function (error) {
    return false;
  }
);

export const newApi = axios.create({
  baseURL: process.env.REACT_APP_BACK_URL,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

newApi.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("userToken");
  // const token = localStorage.getItem("userToken") ? localStorage.getItem("userToken") : "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI1YTNhNjU5NC1hMzgzLTRhYmItYWU0YS0zNTk2ZWYyNDg3OWQiLCJlbWFpbCI6ImFkbWluQGRldi5jb20iLCJuYW1lIjoiQWRtaW4gRGV2Iiwicm9sZSI6IkFETUlOIiwiaWF0IjoxNjkwNTY2Njk5LCJleHAiOjE2OTA2NTMwOTl9.Lx9hT4WAzhTihYIaW9G5Pvh80T41D2cv6jL2OlHCx6k";

  if (token) {
    config.headers = { Authorization: `Bearer ${token}` };
  }

  return config;
});

newApi.interceptors.response.use(
  function (response) {
    return response?.data?.data || false;
  },
  function (error) {
    return false;
  }
);
