import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Menu, Grid } from "antd";
import { DownOutlined } from "@ant-design/icons";
import Icon from "../util-components/Icon";
import navigationConfig, { cockpitMenu, upgradeMenu} from "configs/NavigationConfig";
import { connect } from "react-redux";
import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE } from "constants/ThemeConstant";
import utils from "utils";
import { onMobileNavToggle } from "redux/actions/Theme";
import UpgradeButton from "components/hubkn/upgrade-button";
import { languageText } from "auth/Context";
import { ContractContext } from "components/hubkn/providers/ContractContext";
import Loading from "components/shared-components/Loading";

const { SubMenu } = Menu;
const { useBreakpoint } = Grid;

const bodyText = languageText();
const setLocale = (isLocaleOn, localeKey) =>
  isLocaleOn ? bodyText[localeKey] : localeKey.toString();

const setDefaultOpen = (key) => {
  let keyList = [];
  let keyString = "";
  if (key) {
    const arr = key.split("-");
    for (let index = 0; index < arr.length; index++) {
      const elm = arr[index];
      index === 0 ? (keyString = elm) : (keyString = `${keyString}-${elm}`);
      keyList.push(keyString);
    }
  }
  return keyList;
};

const SideNavContent = (props) => {
  const {
    sideNavTheme,
    routeInfo,
    hideGroupTitle,
    localization,
    onMobileNavToggle,
  } = props;
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes("lg");
  const closeMobileNav = () => {
    if (isMobile) {
      onMobileNavToggle(false);
    }
  };
  const { 
    contract, 
    isContractAboveSCOREPRO 
  } = useContext(ContractContext);

  const getHomeSubmenu = () =>{
    return isContractAboveSCOREPRO() ? 
      cockpitMenu : 
      upgradeMenu;
  };

  return (
    <>
    { contract === undefined ? 
        <Loading/> : 
        <Menu
          theme={sideNavTheme === SIDE_NAV_LIGHT ? "light" : "dark"}
          mode="inline"
          style={{ height: "100%", borderRight: 0 }}
          defaultSelectedKeys={[routeInfo?.key]}
          defaultOpenKeys={setDefaultOpen(routeInfo?.key)}
          className={hideGroupTitle ? "hide-group-title" : ""}
        >
        {navigationConfig.map( menu =>{
          const submenu = menu.key === "home" ? 
            getHomeSubmenu() : 
            menu.submenu;
          return submenu.length > 0 ? (
            <Menu.ItemGroup
              key={menu.key}
              title={setLocale(localization, menu.title)}
            >
              {submenu.map( subMenuFirst =>{
                return subMenuFirst.submenu.length > 0 ? (
                  <SubMenu
                    icon={
                      subMenuFirst.icon ? (
                        <Icon type={subMenuFirst?.icon} />
                      ) : null
                    }
                    key={subMenuFirst.key}
                    title={setLocale(localization, subMenuFirst.title)}
                  >
                    {subMenuFirst.submenu.map((subMenuSecond) => (
                      <Menu.Item key={subMenuSecond.key}>
                        {subMenuSecond.upgrade ? (
                          <UpgradeButton onClick={() => closeMobileNav()} />
                        ) : (
                          <>
                            {subMenuSecond.icon ? (
                              <Icon type={subMenuSecond?.icon} />
                            ) : null}
                            <span>
                              {setLocale(localization, subMenuSecond.title)}
                            </span>
                            <Link
                              onClick={() => closeMobileNav()}
                              to={subMenuSecond.path}
                            />
                          </>
                        )}
                      </Menu.Item>
                    ))}
                  </SubMenu>
                ) : (
                  <Menu.Item key={subMenuFirst.key}>
                    {subMenuFirst.upgrade ? (
                      <UpgradeButton onClick={() => closeMobileNav()} />
                    ) : (
                      <>
                        {subMenuFirst.icon ? (
                          <Icon type={subMenuFirst.icon} />
                        ) : null}
                        <span>{setLocale(localization, subMenuFirst.title)}</span>
                        <Link
                          onClick={() => closeMobileNav()}
                          to={subMenuFirst.path}
                        />
                      </>
                    )}
                  </Menu.Item>
                )
              }
              )}
            </Menu.ItemGroup>
          ) : (
            <Menu.Item key={menu.key}>
              {menu.upgrade ? (
                <UpgradeButton onClick={() => closeMobileNav()} />
              ) : (
                <>
                  {menu.icon ? <Icon type={menu?.icon} /> : null}
                  <span>{setLocale(localization, menu?.title)}</span>
                  {menu.path ? (
                    <Link onClick={() => closeMobileNav()} to={menu.path} />
                  ) : null}
                </>
              )}
            </Menu.Item>
          )
        }
        )}
      </Menu>
    }
    </>
  );
};

const TopNavContent = () => {
  return (
    <Menu mode="horizontal" className="w-100">
      {navigationConfig.map((menu) =>
        menu.submenu.length > 0 ? (
          <SubMenu
            key={menu.key}
            popupClassName="top-nav-menu"
            title={
              <span className="ant-typography-link">
                {menu.icon ? <Icon type={menu?.icon} /> : null}
                <span>{bodyText?.[menu?.title]}</span>
                <DownOutlined className="font-size-xs ml-1"/>
              </span>
            }
          >
            {menu.submenu.map((subMenuFirst) =>
              subMenuFirst.submenu.length > 0 ? (
                <SubMenu
                  key={subMenuFirst.key}
                  popupClassName="top-nav-menu"
                  icon={
                    subMenuFirst.icon ? (
                      <Icon type={subMenuFirst?.icon} />
                    ) : null
                  }
                  title={subMenuFirst.title}
                >
                  {subMenuFirst.submenu.map((subMenuSecond) => (
                    <Menu.Item key={subMenuSecond.key}>
                      <span className="ant-typography-link">
                        {bodyText?.[subMenuSecond?.title]}
                      </span>
                      <Link to={subMenuSecond.path} />
                    </Menu.Item>
                  ))}
                </SubMenu>
              ) : (
                <Menu.Item key={subMenuFirst.key}>
                  {subMenuFirst.icon ? (
                    <Icon type={subMenuFirst?.icon} />
                  ) : null}
                  <span className="ant-link">
                    {bodyText?.[subMenuFirst?.title]}
                  </span>
                  <Link to={subMenuFirst.path} />
                </Menu.Item>
              )
            )}
          </SubMenu>
        ) : (
          <Menu.Item key={menu.key}>
            {menu.icon ? <Icon type={menu?.icon} /> : null}
            <span className="ant-link">
              {bodyText?.[menu?.title]}
            </span>
            {menu.path ? <Link to={menu.path} /> : null}
          </Menu.Item>
        )
      )}
    </Menu>
  );
};

const MenuContent = (props) => {
  return props.type === NAV_TYPE_SIDE ? (
    <SideNavContent {...props} />
  ) : (
    <TopNavContent {...props} />
  );
};

const mapStateToProps = ({ theme }) => {
  const { sideNavTheme, topNavColor } = theme;
  return { sideNavTheme, topNavColor };
};

export default connect(mapStateToProps, { onMobileNavToggle })(MenuContent);
