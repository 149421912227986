import React, { useEffect, useState } from 'react';
import { Dropdown, Badge, Button} from 'antd';
import {  
  BellOutlined, 
} from '@ant-design/icons';
import { NotificationList } from 'components/hubkn/top-nav/top-nav-notification/NotificationList';
import { fetchAllNotifications, setNotificationRead } from 'controllers/NotificationsController';
import { languageText } from 'auth/Context';

const bodyText = languageText();

export const NavNotification = () => {
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState();

  const notRead = data?.length > 0 ? 
    data.filter( notify => !notify.read) : 
    [];
  const read = data?.length > 0 ? 
    data.filter( notify => notify.read) : 
    [];

  const handleVisibleChange = isOpened => {
    if( !isOpened && notRead?.length > 0 ){
      const alreadyRead = notRead.map( async not => {
        await setNotificationRead(not.id,true);
        not.read = true;
        return not;
      });
      const newList = alreadyRead.concat(read);
      setData( newList );
    }
    setVisible(isOpened);
    
  };

  useEffect(()=>{
    let isMounted = true;

    const fetchData = async () => {
      const allNotifications = await fetchAllNotifications();
      if (isMounted) {
        setData(allNotifications);
      }
    };

    if (data === undefined) {
      fetchData();
    }
    return () => {
      isMounted = false;
    };
  },[data]);

  return (
      <Dropdown 
        placement="bottomRight"
        overlay={ 
          <NotificationList 
            notReadList={notRead} 
            alreadyReadList={read}
          />
      }
        onVisibleChange={handleVisibleChange}
        visible={visible}
        trigger={"click"}
      >
          <Button
            className="font-size-lg text-body h-100 px-2"
            type="link"
            title={bodyText?.topNav?.notification?.title}
          >
            <Badge count={notRead?.length}>
              <BellOutlined className="font-size-md"/>
            </Badge>
          </Button>
        </Dropdown>
  )
}

export default NavNotification;
