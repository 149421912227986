import  { Link } from "react-router-dom"
import Icon from '@ant-design/icons';
import { languageText } from "auth/Context";
import { UpgradeSVG } from "./upgradeIcons";

const bodyText = languageText();


export const UpgradeIcon = () => <Icon component={UpgradeSVG}/>

const UpgradeButton = ({onClick}) =>{
    return(
        <>
            <UpgradeIcon/>
            <span className="text-success">
                {bodyText.getUpgrade}
            </span>
            <Link 
                onClick={onClick} 
                to="/app/upgrade"
            />
        </> 
    )
}

export default UpgradeButton