
export const UpgradeSVG = ({currentColor}) =>{
    return(
    <svg 
        width="16" 
        height="16" 
        viewBox="0 0 16 16" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
    >
        <path 
            d="M8.12121 5V10.303V12M8.12121 5L6 7.12121M8.12121 5L10.2424 7.12121" 
            stroke={ currentColor ? 
                'currentColor' : 
                '#7CF7BC'
            } 
            strokeWidth="0.7"
        />
        <circle 
            cx="8" 
            cy="8" 
            r="7.66667" 
            stroke={ currentColor ? 
                'currentColor' : 
                '#7CF7BC'
            } 
            strokeWidth="0.666667"
        />
    </svg>
)}
export const UpgradeSVGColor = () =>{
    return(
    <svg 
        width="16" 
        height="16" 
        viewBox="0 0 16 16" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
    >
        <path 
            d="M8.12121 5V10.303V12M8.12121 5L6 7.12121M8.12121 5L10.2424 7.12121" 
            stroke="currentColor" 
            strokeWidth="0.7"
        />
        <circle 
            cx="8" 
            cy="8" 
            r="7.66667" 
            stroke="currentColor" 
            strokeWidth="0.666667"
        />
    </svg>
)}