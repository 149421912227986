import { Form, Input, Button, Divider, Alert } from "antd";
import Flex from "components/shared-components/Flex";
import { motion } from "framer-motion";
import { CloseOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import jwtDecode from "jwt-decode";
import { languageText } from "auth/Context";
import { login } from "controllers/AuthController";

const { Item } = Form;
const bodyText = languageText();

export const ConfirmIdentity = ({ onLoginSuccess }) => {
    const [user,setUser] = useState(undefined); 
    const [isSubmitting, setSubmitting] = useState(false);
    const [invalidPassword, setInvalidPassword] = useState(false);

    useEffect(()=>{
        if( user === undefined){
            const userToken = localStorage.getItem("userToken");
            const data = jwtDecode(userToken);
            setUser(data);
        }
    },[user]);

    const onPasswordSubmit = async  values =>{
        const { password } = values;
        setSubmitting(true);
        const loginTry = await login({
            email: user?.email,
            password
        });
        if( loginTry?.success){
            if(onLoginSuccess){
                onLoginSuccess();
            }
        }else{
            setInvalidPassword(true);
            setTimeout(()=>{
                setInvalidPassword(false)
            },2500);
        };
        setSubmitting(false);
    };

    const onNotMyAccountClick = () =>{
        localStorage.removeItem("userToken");
        localStorage.removeItem("userEmail");
        window.location = "/auth/login-2";
    };

    return (
        <div style={{
            position:"fixed",
            width:"100vw",
            height:"100vh",
            top:0,
            left:0,
            zIndex:2000,
            background: "rgba(27, 37, 49, 0.1)",
            backdropFilter: "blur(3px)"
        }} className="d-flex align-items-center justify-content-center">
            <motion.div 
                className="d-flex flex-column align-items-center justify-content-center p-5 rounded border" 
                style={{
                    background:"#1B2531",
                    maxWidth: "max-content"
                }}
                initial={{scale:0.7}}
                animate={{scale:1}}
                transition={{duration:0.1}}
            >
                <h2 className="font-size-xl">
                    {user?.name}, {bodyText?.yourSessionHasExpired}
                </h2>
                <p className="text-muted mb-4">
                    {bodyText?.toContinueUsingTheAppConfirmYourIdentity}
                </p>
                <Form 
                    layout="vertical" 
                    onFinish={onPasswordSubmit}
                >
                    <Item 
                        label={bodyText?.confirmPassword} 
                        name="password" 
                        rules={[{
                            required:true, 
                            message: bodyText?.insertAValidPassword
                        }]}
                        
                    >
                        <Input.Password 
                            placeholder={bodyText?.insertYourPassword} 
                            style={{width:400}}
                        />
                    </Item>
                    <Item>
                        <Button 
                            type="primary" 
                            block 
                            htmlType="submit"
                            loading={isSubmitting}
                            disabled={isSubmitting}
                        >
                            {bodyText?.confirm}
                        </Button>
                    </Item>
                    { invalidPassword ? 
                        <>
                            <motion.div 
                                initial={{height:0}} 
                                animate={{height:"auto"}}
                                transition={{duration:0.35}}
                                style={{overflow:"hidden"}}
                            >
                                <Alert 
                                    message={bodyText?.invalidPassword}
                                    type="error"
                                    showIcon
                                />
                            </motion.div>
                        </> : 
                        null
                    }
                </Form>
                <Divider className="opacity-0-3"/>
                <Flex justifyContent="between" className="w-100">
                    <Button 
                        type="link" 
                        className="p-0" 
                        href="/auth/forgot-password"
                    >
                        {bodyText?.forgotPassword}
                    </Button>
                    <Button 
                        type="link" 
                        className="p-0 text-warning" 
                        icon={<CloseOutlined/>}
                        onClick={onNotMyAccountClick}
                    >
                        {bodyText?.notMyAccount}
                    </Button>
                </Flex>
            </motion.div>
        </div>
    )
}
