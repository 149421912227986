import jwtDecode from "jwt-decode";
import { newApi } from "./ApiClient";
import { getCurrentContract, isClientIntegrated } from "./ClientsController";

export const login = async ({ email, password }) => {
    const body = {
        email,
        password
    };
    const result = await newApi.post("/auth/login", body);
    if (result?.access_token) {
        const user = jwtDecode(result.access_token);
        localStorage.setItem("userToken", result.access_token);
        user.integrated = await isClientIntegrated();
        user.contract   = await getCurrentContract();
        return {
            success: true,
            user: user,
            token: result.access_token
        };
    } else {
        return {
            success: false
        };
    }
}

export const requestNewPassword = async ({ email, language }) => {
    const body = { email, language };
    return newApi.post("/auth/forgot-password", body);
}
