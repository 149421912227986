import { contractList, contractType, getContract, getTrial } from "controllers/ClientsController";
import { createContext, useContext, useEffect, useState, } from "react"
import { AuthContext, loggedType } from "./AuthContext";
import { contractValue } from "../contract-validation/ContractValidation";

export const ContractContext = createContext({});

export const ContractProvider = ({ children }) => {
    const [contract,setContract] = useState(undefined);
    const { isLogged } = useContext(AuthContext);

    const loggedChangeToLogged = isLogged === loggedType.LOGGED;
    const loggedChangedToExpire = isLogged === loggedType.EXPIRED;

    const fetchContract = async () => {
        const trial = await getTrial();
        if(trial){
            setContract(trial);
        }else{
            const clientContract = await getContract();
            setContract(clientContract);
        }
    };

    useEffect(()=>{
        if(contract === undefined){
            fetchContract();
        }
    },[contract]);

    useEffect(()=>{
        if(loggedChangeToLogged){
            fetchContract();
        }else if(loggedChangedToExpire){
            setContract(contractType.NO_CONTRACT);
        }
    },[isLogged]);

    const changeContract = newContract =>{
        const exist = !!contractList.find( contractOnList => 
            contractOnList === newContract
        );
        if(exist){
            setContract(newContract);
        }
    };

    const isContractAboveSCOREPRO = () =>{
        return (
            contract !== undefined && 
            contract !== contractType.NO_CONTRACT &&
            contractValue[contract] > contractValue[contractType.SCOREPRO]
        );
    };

    const isScorePro = () =>{
        return (
            contract !== undefined && 
            contract !== contractType.NO_CONTRACT &&
            contractValue[contract] >= contractValue[contractType.SCOREPRO]
        );
    };

    return(
        <ContractContext.Provider
            value={{
                contract,
                changeContract,
                isContractAboveSCOREPRO,
                isScorePro
            }}
        >
            {children}
        </ContractContext.Provider>
    )
}