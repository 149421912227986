import { Button, Dropdown, Menu, Modal} from "antd";
import { GlobalOutlined, CheckOutlined } from "@ant-design/icons";
import { languageText } from "auth/Context";

const { confirm } = Modal;
const text = languageText();
const languages = [{
    name: text.en,
    flag:"/img/flags/us.png",
    lang:"en-US"
},{
    name: text.ptBr,
    flag:"/img/flags/br.png",
    lang:"pt-BR"
}];

function getStoragedLang(){
    const storagedLang = localStorage.getItem('lang');
    const lang = storagedLang === undefined ? '' : storagedLang.split("-")[0]
    return lang.toLocaleLowerCase();
};

function isCurrentLanguage( language ){
    const storagedLang = getStoragedLang();
    const lang = getLangKey(language);
    return lang === storagedLang;
};

function getLangKey( lang ){
    return lang.split("-")[0];
};

const LanguageMenuItem = (props) =>{
    const { flag, name, lang } = props;
    function showConfirm(langKey, langValue) {
        confirm({
            title: `${text.changeLangPopTitle} ${text[langKey]}?`,
            content: <span className='text-muted'>{text.changeLangPopDescription}</span>,
            okText: text.changeLang,
            cancelText: text.cancel,
            onOk() {
                localStorage.setItem("lang", langValue);
                window.location.reload();
            },
            onCancel() { },
        });
    };
    const changeLang = () => {
        const langKey = getLangKey(lang);
        const storedLang = getStoragedLang();
        if( storedLang !== '' && langKey !== storedLang){
            showConfirm(langKey, lang);
        };
    };
    return (
        <Menu.Item 
            key={name} 
            className={ isCurrentLanguage(lang) ? 
                'ant-dropdown-menu-item-active' : 
                ''
            }
            onClick={changeLang}
        >
            <div className="d-flex align-items-center">
                <img style={{maxWidth: '20px'}} src={flag} alt={name}/>
                <span className="font-weight-semibold ml-2">
                    {name}
                    { isCurrentLanguage(lang) && <CheckOutlined className="text-success ml-2"/>}
                </span>
            </div>
        </Menu.Item>
	)
};

const LanguageOptions = () =>{
    return(
        <Menu>
            { languages.map( language => 
                <LanguageMenuItem 
                    flag={language.flag} 
                    name={language.name}
                    lang={language.lang}
                    key={`${language.name}-${language.lang}`}
                />
            )}
        </Menu>
    )
};

export const TopNavLanguage = () => {
    return (
        <Dropdown 
            overlay={LanguageOptions} 
            destroyPopupOnHide 
            trigger="click"
        >
            <Button
                className="font-size-lg text-body h-100 px-2"
                type="link"
                title={text.changeLang}
            >
                <GlobalOutlined className="font-size-md text-body "/>
            </Button>
        </Dropdown>
    )
};