import React from 'react';
import { connect } from 'react-redux';
import Loading from 'components/shared-components/Loading';
import MobileNav from 'components/layout-components/MobileNav'
import HeaderNav from 'components/layout-components/HeaderNav';
import PageHeader from 'components/layout-components/PageHeader';
import Footer from 'components/layout-components/Footer';
import AppViews from 'views/app-views';
import {
  Layout,
  Grid,
} from "antd";

import navigationConfig from "configs/NavigationConfig";
import {
  NAV_TYPE_TOP,
} from 'constants/ThemeConstant';
import utils from 'utils';
import { useThemeSwitcher } from "react-css-theme-switcher";
import { ContractProvider } from 'components/hubkn/providers/ContractContext';
import { AuthProvider } from 'components/hubkn/providers/AuthContext';

const { Content } = Layout;
const { useBreakpoint } = Grid;

export const AppLayout = ({  navType, location }) => {
  const currentRouteInfo = utils.getRouteInfo(navigationConfig, location.pathname)
  const screens = utils.getBreakPoint(useBreakpoint());
  const isMobile = !screens.includes('lg')
  const isNavTop = navType === NAV_TYPE_TOP
  const { status } = useThemeSwitcher();

  if (status === 'loading') {
    return <Loading cover="page" />;
  }

  return (
    <Layout>
      <AuthProvider>
      <ContractProvider>
        <HeaderNav isMobile={isMobile}/>
          <Layout className="app-container">
            <Layout className="app-layout" >
              <div className={`app-content ${isNavTop ? 'layout-top-nav' : ''}`}>
                <PageHeader display={currentRouteInfo?.breadcrumb} title={currentRouteInfo?.title} />
                <Content className="contentContainer">
                  <AppViews />
                </Content>
              </div>
              <Footer />
            </Layout>
          </Layout>
        {isMobile && <MobileNav />}
        </ContractProvider>
      </AuthProvider>
    </Layout>
  )
}

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, navType, locale } =  theme;
  return { navCollapsed, navType, locale }
};

export default connect(mapStateToProps)(React.memo(AppLayout));