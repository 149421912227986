import { useContext } from "react";
import { ContractContext } from "../providers/ContractContext";
import { NotAllowedAccess } from "./NotAllowedAccess";
import Loading from "components/shared-components/Loading";
import Flex from "components/shared-components/Flex";

export const contractValue = {
    "NO_CONTRACT": 0,
    "SCORE": 1,
    "SCOREPRO": 2,
    "COCKPIT": 3,
    "SPACESHIP": 4,
    "":0
};

function isValidContract(contract, requires){
    const pageKey = contractValue[requires];
    const contractKey = contractValue[contract];
    return Number(contractKey) >= Number(pageKey);
};

const WaitingForContract = () => {
    return (
        <Flex 
            className="w-100 py-5" 
            alignItems="center" 
            justifyContent="center"
            style={{height:"300px"}}
        >
            <Loading/>
        </Flex>
    )
};

export const ContractValidation = ({children, requires}) => {
    const { contract } = useContext(ContractContext);

    const Content = () =>{
        return  isValidContract(contract, requires) ? 
            children : 
            <NotAllowedAccess/>
    };

    return(
        <>
            { contract === undefined ? 
                <WaitingForContract/> : 
                <Content/>
            }
        </>
    )
}