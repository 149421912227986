import { Badge, List, Tabs } from "antd"
import { languageText } from "auth/Context";
import Flex from "components/shared-components/Flex";

const { TabPane } = Tabs;
const bodyText = languageText();

const getNotificationBody = list => {
    return list.length > 0 ?
    <List
      size="small"
      itemLayout="horizontal"
      dataSource={list}
      renderItem={item => (
        <List.Item className="list-clickable">
          <Flex alignItems="center" className="px-3 py-1">
            <span>
              {item?.content?.text}
            </span>
          </Flex>
        </List.Item>
      )}
    />
    :
    <div className="empty-notification">
      <img src="https://gw.alipayobjects.com/zos/rmsportal/sAuJeJzSKbUmHfBQRzmZ.svg" alt="empty" />
      <p className="mt-3">
        {bodyText?.topNav?.notification?.allNotificationViewed}
      </p>
    </div>;
  }


export const NotificationList = ({notReadList, alreadyReadList }) => {
    return(
        <div className="nav-dropdown nav-notification">
            <div className="nav-notification-header d-flex justify-content-between align-items-center">
                <h4 className="mb-0">
                    {bodyText?.topNav?.notification?.title}
                </h4>
            </div>
            <Tabs>
                { notReadList?.length > 0 ? 
                    <TabPane 
                        tab={
                            <span>
                                { bodyText?.topNav?.notification?.new }
                                <Badge count={notReadList?.length} className="ml-2"/>
                            </span>
                        } 
                        key={0}
                    >
                        { getNotificationBody(notReadList) }
                    </TabPane>
                    : null 
                }
                <TabPane 
                    tab={ 
                        <span>
                            { bodyText?.topNav?.notification?.all }
                            <Badge 
                                count={alreadyReadList?.length} 
                                className="ml-2" 
                                style={{ backgroundColor: "#04D182" }}
                            />
                        </span>
                    } 
                    key={1}
                >
                    { getNotificationBody(alreadyReadList) }
                </TabPane>
            </Tabs>
        </div>
    )
};