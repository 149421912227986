import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";
import TagManager from "react-gtm-module";

const usePageTracking = () => {
  const location = useLocation();
  useEffect(() => {
    const tagManagerArgs = {
      gtmId: "GTM-MGZX5TH",
    };

    TagManager.initialize(tagManagerArgs);
    ReactGA.initialize("UA-217164636-1");
    ReactGA.initialize("G-17WR53PGB3");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [location]);
};

export default usePageTracking;
