import { api, newApi } from "./ApiClient";

// Utilizar para apresentar as notificações
export const fetchAllNotifications = async () => {
  return await newApi.get("/notifications/");
};

export const fetchNotificationsNotRead = async () => {
  const params = {
    read: false,
  };
  try {
    const notification = await newApi.get("/notifications", {
      params,
    });
    return notification;
  } catch (error) {
    console.log("Falha a buscar notificações > " + error);
    return [];
  }
};

export const setNotificationRead = async (notificationId, read) => {
  const body = {
    read,
  };
  try {
    const notification = await newApi.patch(
      `/notifications/${notificationId}`,
      body
    );
    return notification;
  } catch (error) {
    console.log("Falha ao atualizar notificações > " + error);
    return [];
  }
};

// Utilizar para as configurações de notificações
export const fetchAllNotificationsRegisters = async () => {
  try {
    const notification = await newApi.get("/notifications/register");
    return notification;
  } catch (error) {
    console.log("Falha a buscar notificações > " + error);
    return [];
  }
};

export const createNotificationRegister = async (
  reference,
  type,
  typeId,
  condition,
  conditionId
) => {
  const body = {
    reference,
    type,
    typeId,
    condition,
    conditionId,
  };
  try {
    const notificationRegister = await newApi.post(
      "/notifications/register",
      body
    );
    return notificationRegister;
  } catch (error) {
    console.log("Falha ao criar notificação > " + error);
    return [];
  }
};

export const updateNotificationRegister = async (
  reference,
  type,
  typeId,
  condition,
  conditionId
) => {
  const body = {
    reference,
    type,
    typeId,
    condition,
    conditionId,
  };
  try {
    const notificationRegister = await newApi.post(
      "/notifications/register",
      body
    );
    return notificationRegister;
  } catch (error) {
    console.log("Falha ao criar notificação > " + error);
    return [];
  }
};

export const deleteNotificationRegister = async (notificationId) => {
  try {
    const notificationRegister = await newApi.delete(
      `/notifications/register/${notificationId}`
    );
    return notificationRegister;
  } catch (error) {
    console.log("Falha ao deleletar notificação > " + error);
    return [];
  }
};

export const fetchAllNotificationRegisters = async () => {
  try {
    const notification = await api.get("/notifications");
    return notification;
  } catch (error) {
    console.log("Falha a buscar notificação > " + error);
    return [];
  }
};
