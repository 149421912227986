import React, { Component } from "react";
import { LogoutOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { connect } from "react-redux";
import { languageText } from "auth/Context";

const bodyText = languageText();

const signOut = () => {
  localStorage.clear();
  window.location.href = "/auth/login-2";
};
export class NavPanel extends Component {
  state = { visible: false };

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  render() {
    return (
      <Button
        className="font-size-lg text-body h-100 px-2"
        type="link"
        onClick={signOut}
        title={bodyText?.topNav?.logout}
      >
          <LogoutOutlined className="font-size-md"/>
      </Button>
    );
  }
}

const mapStateToProps = ({ theme }) => {
  const { locale } = theme;
  return { locale };
};

export default connect(mapStateToProps)(NavPanel);
