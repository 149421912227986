import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { AUTH_PREFIX_PATH } from "configs/AppConfig";

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="page" />}>
      <Switch>
        <Route path={`${AUTH_PREFIX_PATH}/login`} component={lazy(() => import(`./authentication/login-2/LoginTwo`))} />
        <Route path={`${AUTH_PREFIX_PATH}/login-1`} component={lazy(() => import(`./authentication/login-1`))} />
        <Route path={`${AUTH_PREFIX_PATH}/login-2/`} component={lazy(() => import(`./authentication/login-2`))} />
        <Route path={`${AUTH_PREFIX_PATH}/register-1`} component={lazy(() => import(`./authentication/register-1`))} />
        <Route path={`${AUTH_PREFIX_PATH}/register-2`} component={lazy(() => import(`./authentication/register-2`))} />
        <Route
          path={`${AUTH_PREFIX_PATH}/integration/:token`}
          component={lazy(() => import(`./authentication/integration`))}
        />
        <Route
          path={`${AUTH_PREFIX_PATH}/integration-done/`}
          component={lazy(() => import(`./authentication/integration-done/`))}
        />
        <Route
          path={`${AUTH_PREFIX_PATH}/forgot-password`}
          component={lazy(() => import(`./authentication/forgot-password`))}
        />
        <Route
          path={`${AUTH_PREFIX_PATH}/reset-password/:id`}
          component={lazy(() => import(`./authentication/reset-password`))}
        />
        <Route path={`${AUTH_PREFIX_PATH}/error-1`} component={lazy(() => import(`./errors/error-page-1`))} />
        <Route path={`${AUTH_PREFIX_PATH}/error-2`} component={lazy(() => import(`./errors/error-page-2`))} />
        <Route
          path={`${AUTH_PREFIX_PATH}/integration-progress/:code`}
          component={lazy(() => import(`./authentication/integration-progress`))}
        />
        <Route path={`${AUTH_PREFIX_PATH}/terms`} component={lazy(() => import(`./pages/terms`))} />
        <Route path={`${AUTH_PREFIX_PATH}/privacy`} component={lazy(() => import(`./pages/privacy`))} />
        <Route path={`${AUTH_PREFIX_PATH}/welcome`} component={lazy(() => import(`./authentication/welcome-screen`))} />
        <Redirect from={`${AUTH_PREFIX_PATH}`} to={`${AUTH_PREFIX_PATH}/login-2`} />
      </Switch>
    </Suspense>
  );
};

export default AppViews;
