import { Tooltip, Button } from "antd";
import { SettingOutlined } from "@ant-design/icons";
import { languageText } from "auth/Context";

const text = languageText();

const RedirectToSettings = () => {
  window.location.href = "/app/settings";
};

export const NavSettings = () => {
    return (
        <Button
          className="font-size-lg text-body h-100 px-2"
          type="link"
          onClick={RedirectToSettings}
          title={text?.settings?.title}
        >
          <SettingOutlined className="font-size-md"/>
        </Button>
    )
};