import { createContext, useState } from "react";
import SomethingWentWrong from "../went-wrong/SomethingWentWrong";

export const OverlayContext = createContext({});

const OverlayProvider = ({children}) =>{
    const [wrong,showWrong] = useState(<></>)
    const renderWentWrong = () => showWrong(<SomethingWentWrong/>);
    const addOverlay = comp => showWrong(comp);
    const clearOverlay = () => showWrong(null);
    return(
        <OverlayContext.Provider value={{
            renderWentWrong,
            addOverlay,
            clearOverlay
        }}>
            {children}
            {wrong}
        </OverlayContext.Provider>
    )
};

export default OverlayProvider;