import React from 'react';
import { APP_NAME } from 'configs/AppConfig';
import { connect } from "react-redux";
import utils from 'utils';
import { Grid } from 'antd';

const { useBreakpoint } = Grid;

const getLogo = (props) => {
  const { navCollapsed, logoType } = props;
  if(logoType === 'light') {
    if(navCollapsed) {
      return '/img/logo-sm.svg'
    }
    return '/img/hubkn-logo-125.svg'
  }
  if (navCollapsed) {
    return '/img/logo-sm.svg'
  }
  return '/img/hubkn-logo-125.svg'
};

const getLogoDisplay = (isMobile, mobileLogo) => {
  if(isMobile && !mobileLogo) {
    return 'd-none'
  } else {
    return 'logo'
  }
};

export const Logo = (props) => {
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes('lg')
  return (
    <div
      className={`${getLogoDisplay(isMobile, props.mobileLogo)}`} 
      style={{width: `auto`}}
    >
      <img 
        src={getLogo(props)} 
        alt={`${APP_NAME} logo`} 
        title='hubkn inc'
      />
    </div>
  )
};

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, navType } =  theme;
  return { navCollapsed, navType }
};

export default connect(mapStateToProps)(Logo);
