import { Button } from "antd";
import Flex from "components/shared-components/Flex";
import { THEME_CONFIG } from "configs/AppConfig";
import { motion } from "framer-motion";

const illustration = "/img/illustration/something-went-wrong-illustration.svg"
const wentWrongStyle = {
    position:"fixed",
    width:"100vw",
    height:"100vh",
    top:0,
    left:0,
    zIndex:2000,
    background: THEME_CONFIG.currentTheme === "dark" ? '#1B2531' : '#fff'
}

const SomethingWentWrong = () =>{
    return(
        <motion.div
            initial={{scale:0.1}}
            animate={{scale:1}}
            transition={{duration:0.2}}
            className="p-5 d-flex align-items-center justify-content-center"
            style={wentWrongStyle}
        >
            <Flex flexDirection="column" justifyContent="center" alignItems="center">
                <img src={illustration} width={282} alt="something went wrong illustration"/>
                <Flex flexDirection="column" justifyContent="center" alignItems="center" className="mt-4">
                    <span className="font-size-xl">
                        Ops...parece que algo saiu errado.
                    </span>
                    <span className="text-muted mb-4 mt-2">
                        Sessão expirada, por favor faça login novamente!                        
                    </span>
                    <Button type="primary" onClick={()=>{window.location = "/auth/login-2"}} style={{maxWidth:"min-content"}}>
                        fazer login
                    </Button>
                </Flex>
            </Flex>
        </motion.div>
    )
};


export default SomethingWentWrong;