import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { APP_PREFIX_PATH } from "configs/AppConfig";

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route
          path={`${APP_PREFIX_PATH}/home`}
          component={lazy(() => import(`./home`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/sdr`}
          component={lazy(() => import(`./sdr`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/closers`}
          component={lazy(() => import(`./closers`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/crmscore`}
          component={lazy(() => import(`./crm`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/settings`}
          component={lazy(() => import(`./settings`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/upgrade`}
          component={lazy(() => import(`./upgrade`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/upgrade-success`}
          component={lazy(() => import(`./upgrade-success`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/owners-settings`}
          component={lazy(() => import(`./owners`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/conversion`}
          component={lazy(() => import(`./conversion`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/activities`}
          component={lazy(() => import(`./activities`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/individual`}
          component={lazy(() => import(`./individual`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/marketing`}
          component={lazy(() => import(`./marketing`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/hubkn-dashboard`}
          component={lazy(() => import(`./hubkn-dashboard`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/tv-mode`}
          component={lazy(() => import(`./tv-mode`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/basic-forecast`}
          component={lazy(() => import(`./forecast`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/integration-guide`}
          component={lazy(() => import(`./integration-guide`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/webhooks/rdstation`}
          component={lazy(() => import(`./webhooks/rdstation`))}
        />
        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/home`} />
      </Switch>
    </Suspense>
  );
};

export default React.memo(AppViews);
